footer { 
  @extend %padding-large;
  width: 100%;
  bottom: 0;
  left: 0;
  text-align: center; 
  small { text-transform: uppercase; }
  clear: both;
}

.sig {
    display: block;
    margin: 2em auto;
    width: 3em;
      max-width: 90px;
}