header {
  width: 100%;
  background: $bg-color;
  border-bottom: 1px solid $border-color;
  margin-bottom: $spacing-l;
}

.no-border {
  border: none;
}

.shaded {
background: #222;
}

.h-wrap {
  @extend %padding-large;

  .avatar {
    display: block;
    float: left;
    margin-right: 0;
    margin-bottom: 0;
    margin: -1em 0em 0em -1em;
    padding: 0;
    img {
    display: block;
    margin: 0 auto;
      width: 3em;
      border-radius: 50%;
    }
  }

  h1.title {
    @extend h3;
    display: inline-block;
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-size: 100%;
    text-transform: uppercase;
    a {
      color: #999;
      text-decoration: none;
      &:hover {
        color: $link-color;
      }
    }
  } // end h1.title

  h1.title--shaded {
    @extend h3;
    display: inline-block;
    margin: 0;
    padding: 0;
    font-weight: bold;
    text-transform: uppercase;
    a {
      color: #aaa;
      text-decoration: none;
      &:hover {
        color: $link-color;
      }
    }
  } // end h1.title

  .close {
    .lines {
      -webkit-transition: background .3s 0s ease;
      transition:         background .3s 0s ease;
      background: transparent;

      &::before,
      &::after {
        top:0;
        width: 30px;
        -webkit-transition: top .3s ease,
                            transform .3s .5s ease;
        transition:         top .3s ease,
                            transform .3s .5s ease;
      }

      &::before {
        transform:         rotate(45deg);
        -webkit-transform: rotate(45deg);
        margin: 0;
      }

      &::after {
        transform:         rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        margin: 0;
      }

    } // end .lines
  } // end .close
} // end .h-wrap

.menu-icon {
  padding: 1em;
  margin: 1em;
  position: fixed;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 200;
  background: $bg-color;
  &:hover {
    opacity: 1;
  }

  .lines {
    display: inline-block;
    position: relative;
    width: 25px;
    height: 2px;
    background: $text-color;
    border-radius: 20px;

    &::before,
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      width: 25px;
      height: 2px;
      background: $text-color;
      border-radius: 20px;

      transition: all .3s ease;
      -webkit-transition: all .3s ease;
    }

    &::before { top: 8px; }
    &::after { top: -8px; }

    &.toggled {
      background: transparent;
      &::before {
        top: 0;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
      &::after {
        top: 0;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }
  } // .lines

} // end .menu-icon



// Off-canvas

.site-wrapper {
  @extend %transition;
  float: right;
  width: 75%;
  // position: absolute;
  // top: 0;
  // right: 0;
  // width: 100%;
  @media (max-width: 684px) {
    float: none;
    clear: both;
    width: 100%;
  }
  &.open {
    .menu-icon {
      position: fixed;
    }
  }
}

.off-canvas {
  @extend %transition;
  @extend %padding-large;
  position: fixed;
  top: 0;
  right: -20em;
  width: 15em;
  padding-right: 2em;
  // height: 100%;
  background: $smoke-color;
  z-index: 100;
  overflow: visible;
  // font-size: 90%;


  // insuring that off-canvas doesn't take more than the average iPhone portrait width under 400px
  @media (max-width: 399px) {
    max-width: 210px;
  }

  &.toggled {
    right: 0;
    border-left: 1px solid $border-color;
  }

  nav {

    h6 {
      text-align: left;
      margin-top: $spacing-m;
      margin-bottom: $spacing-s;
      color: lighten($text-color, 50%);
      padding-bottom: $spacing-xs;
      border-bottom: 1px solid $border-color;
    }
    ul li {
      list-style-type: none;
      margin-bottom: $spacing-xs;
      font-weight: bold;
      font-size: $font-size-m;
      text-transform: uppercase;
      span {
        display: none;
      }
      a {
        text-decoration: none;
        color: #999;
        &:hover {
        color: $main-color;
        }
        }
      }
    }


} // end .off-canvas

.sidebar {
  @extend %transition;
  @extend %padding-large;
  float: left;
  width: 20%;
  max-width: 250px;
  min-width: 170px;
  background-color: $bg-color;
  font-size: 0.9em;
  padding-top: 1em;
    @media (max-width: 684px) {
    float: none;
    clear: both;
    width: 100%;
    font-size: 100%;
    max-width: 684px;
    padding-top: 1em;
    margin-top: 1em;
  }


  .avatar {
    display: block;
    margin-bottom: $spacing-s;
    padding: 1em;
    img {
    display: block;
    margin: 0 auto;
      width: 6em;
      border-radius: 50%;
    }
  } // end .avatar


  .bio {
    display: block;
     h3 {
     font-weight: bold;
     font-size: 1em;
      color: lighten($text-color, 30%);
      margin-bottom: 0;
     }
     p {
      font-size: 0.9em;
      color: lighten($text-color, 30%);
      margin-bottom: 0;
      font-family: $variant-family;
    }
    .tipjar {
    margin: $spacing-s auto;
    	form {
    	text-align: center;
		input[type="image"] {
		display: inline-block;
		margin: 0 auto;
		}
		}
    }
  }

   nav {
    margin: 1em 0;
    font-size: 0.9em;
    font-family: $variant-family;
    }
    nav ul li {
    list-style-type: none;
    margin: 0.5em 0;
    padding-left: 1em;
      a {
      text-decoration: none;
      color: $text-color;
      &:hover {
        color: $main-color;
        }
      }
    }

}
