* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  &::after,
  &::before {
    box-sizing: border-box;
  }
}

html {
  width: 100%;
  height: 100%;
}

body {
  font: $font-size-l $font-family;
  color: $text-color;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  background-color: $bg-color;
  @media (max-width: 942px) {
    font-size: $font-size-m; 
  }
  @media (max-width: 642px) {
    font-size: $font-size-s; 
  }
}

h1, 
h2, 
h3,
h4,
h5,
h6 {
  font-family: $font-family;
}

h1 { 
  font-size: 250%; 
  margin: $spacing-xs 0;
}

h2 { 
  font-size: 180%; 
  // font-style: italic; 
  margin: $spacing-s 0 $spacing-xs;
  a {
  color: $text-color;
  text-decoration: none;
    &:hover {
    color: $link-color;
    text-decoration: none;
    }
  }
}

h3 { 
  font-size: 150%; 
  font-weight: normal; 
  margin: $spacing-s 0 $spacing-xs;
  a {
  color: $text-color;
  text-decoration: none;
    &:hover {
    color: $link-color;
    text-decoration: none;
    }
  }
}

h4 { 
  font-size: 80%; 
  font-weight: bold;
  text-transform: uppercase; 
  letter-spacing: 3px;
  margin: $spacing-l 0 $spacing-xs;
  color: lighten($text-color, 40%);
}

h5 { 
  @extend h4;
  text-align: center;
}

h6 { 
  @extend h4;
  text-align: right;
}

p { 
  margin: 1.3em 0; 
  line-height: 1.7;
}

small { 
  font-size: 60%; 
}

ul { margin: 0; padding: 0; }
ul li { list-style-type: square; line-height: 1.6; margin-left: 1em; padding-left: 0.5em;}
ol li { list-style-type: decimal; line-height: 1.6; }

a { 
  color: $link-color; 
  &:hover {
    color: $text-color;
  }
}
