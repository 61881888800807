
.pagination {
  width: 100%;
  max-width: 740px;
  margin: $spacing-s auto;
  font-family: $variant-family;
  background: white;
  z-index: 1;
    padding: 0 $spacing-m;
    @media (max-width: 642px) {
      padding-bottom: $spacing-l;
      background: $bg-color;
    }
} // end .pagination


.page__hero {
  position: relative;
  margin-bottom: 2em;
//  @include clearfix;
  animation: intro 0.3s both;
  animation-delay: 0.25s;
  text-align: center;
  padding: 2em 10em 3em 10em;

  &--overlay {
    position: relative;
    margin-bottom: 2em;
    padding: 3em 0;
//    @include clearfix;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    animation: intro 0.3s both;
    animation-delay: 0.25s;

    .wrapper {
      padding-left: 2em;
      padding-right: 2em;
      margin: 0 auto;
      max-width: 740px;
      text-align: center;

      .avatar {
        display: block;
        margin-bottom: $spacing-s;
        padding: 1em;
        img {
        display: block;
        margin: 0 auto;
          width: 6em;
          border-radius: 50%;
          border: 4px solid #fff;
        }
      }

    }

    .page__title,
    .page__meta,
    .page__lead,
    .btn {
      color: #fff;
      text-shadow: 1px 1px 4px rgba(#000, 0.8);
    }

    .page__lead {
      a {
        color: #e1c1c1;
        text-decoration: none;

        &:hover {
          color: $main-color;
        }
      }
    }

    .page__title {
//      font-size: $font-size-l;
    }
  }
}

.splashpage {
    color: #fff;
    background: linear-gradient(to bottom, rgba(0, 80, 107, 0.75), rgba(20, 70, 65, 0.65)), url(/assets/img/bann6a.jpg) center center no-repeat;
}

.bookpage {
    color: #fff;
    background: linear-gradient(to bottom, rgba(120, 0, 0, 0.75), rgba(70, 10, 6, 0.85)), url(/assets/img/booksback.jpg) center center no-repeat;
}

.editpage {
    color: #fff;
    background: linear-gradient(to bottom, rgba(58, 165, 0, 0.75), rgba(40, 95, 12, 0.85)), url(/assets/img/editback.jpg) center center no-repeat;
}

.page__hero-image {
  width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

.page__hero-caption {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  padding: 2px 5px;
  color: #fff;
  font-family: $font-family;
  font-size: $font-size-s;
  background: #000;
  text-align: right;
  z-index: 5;
  opacity: 0.5;
  border-radius: $border-radius 0 $border-radius 0;

  a {
    color: #fff;
    text-decoration: none;
  }
}


.feature_left {
		width: 45%;
		float: left;
		text-align: center;
    padding: $spacing-m;
    		@media (max-width: 642px) {
      		float: none;
				width: 100%;
    			font-size: $font-size-m;
    		}
		}

.feature_right {
		width: 45%;
		float: right;
		text-align: center;
    padding: $spacing-m;
    		@media (max-width: 642px) {
      		float: none;
				width: 100%;
    			font-size: $font-size-m;
    		}
		}

.feature-image {
    display: block;
		margin: $spacing-s auto;
		max-width: 100px;
		height: 100px;
    border-radius: 50%;
    border: 4px solid $text-color;
}

.contactblock {
    clear: both;
    width: 100%;
    text-align: center;
    margin: 5em 0 0 0;
    padding: 2em 5em;
    color: $bg-color;
    background-color: $text-color;

    ul {
      padding: 0;
      margin: 2em 0;

        li {
          display: inline;
          margin: $spacing-xs $spacing-s;

          a {
            text-decoration: none;
            color: $bg-color;

            &:hover {
              text-decoration: none;
              color: $link-color;
            }

            .icon-twitter, .icon-github, .icon-dribbble, .icon-instagram, .auth-twitter, .auth-facebook, .auth-linkedin, .auth-instagram, .auth-email, .auth-steam, .nav-home, .nav-about, .nav-archive, .nav-search, .meta-date, .meta-tags, .linkpost, .icon-feed, .downicon, .nav-editing, .nav-blog {
              background: $text-color;
            }
          }
        }
    }
}


.btn {
	display: inline-block;
	margin-bottom: $spacing-xs;
	padding: $spacing-xs $spacing-s;
	text-align: center;
	text-decoration: none;
	border-radius: 3px;
	-moz-border-radius: 3px;
	color: $bg-color !important;
	background-color: $text-color;
	border: 0 !important;
	cursor: pointer;
	font-weight: bold;
	font-family: $font-family;
    @media (max-width: 642px) {
    }

		a {
		color: $bg-color !important;
		text-decoration: none;

		&:hover {
		color: white;
		text-decoration: none;
		}
		}
}

.btninverse {
		color: $text-color !important;
		border: 1px solid $text-color !important;
		background-color: $bg-color;

		&:hover {
			color: $bg-color !important;
			border-color: $deep-color;
      background-color: $link-color;
		}
	}
