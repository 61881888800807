.intro-wrap {
  width: 100%;
  max-width: 740px;
  margin: $spacing-s auto;
  font-family: $variant-family;
  background: $bg-color;
  z-index: 1;
  margin-bottom: $spacing-xl;

  .single-content {
    padding: 0 $spacing-m;
    font-size: $font-size-l;
    @media (max-width: 642px) {
      padding-bottom: $spacing-m;
    font-size: $font-size-m;
    }
  }

}

.single-wrap {
  width: 100%;
  max-width: 740px;
  margin: $spacing-s auto;
  font-family: $variant-family;
  background: $bg-color;
  z-index: 1;
  margin-bottom: $spacing-m;

  .single-content {
    padding: 0 $spacing-m;
    font-size: $font-size-l;
    @media (max-width: 642px) {
      padding-bottom: $spacing-s;
    font-size: $font-size-m;
    }
  }

  .rightbox {
  float: right;
  width: 65%;
  }

  .book-links {
    font-size: $font-size-m;
    padding: 0;
    margin: 0 0 $spacing-m 0;

      li {
        list-style-type: none;
        display: inline;
        margin-left: 0;
        padding-left: 0;
        margin-right: $spacing-xs;
        padding-right: $spacing-xs;
        border-right: 1px solid #999;
        text-align: left;
      }
        .last {
          border-right: none;
        }
  }

  .feat {
    text-align: center;
    border-bottom: 1px dotted $border-color;
    margin: 0 auto $spacing-l;
    a.popup {
      display: inline-block;
      position: relative;
      top: .7em;
      background: #fff;
      font-size: 130%;
      text-align: center;
      text-decoration: none;
      padding: 0 $spacing-s;
      transition: all .2s ease;
      -webkit-transition: all .2s ease;
      @media (max-width: 642px) {
        font-size: 150%;
      }
    }
  }

  h5.article-meta {
    // display: inline-block;
    // position: relative;
    // top: 1.1em;
    background: $bg-color;
    font-family: $font-family;
    font-size: 75%;
    text-align: left;
    font-weight: normal;
    font-style: none;
    padding: 0;
    margin: 0;
    text-transform: none;
    letter-spacing: 0px;
    a {
    text-decoration: none;
    }
  }

  .share {
    margin: $spacing-l auto;
  }

  h1.page-title, h2.page-title {
    font-size: 180%;
    line-height: 135%;
    font-family: $font-family;
    text-align: left;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    font-style: none;
  }

  h2.page-title a {
  color: $text-color;
  	&:hover {
  	color: $link-color;
  	}
  text-decoration: none;
  }

  ul,
  ol {
    margin: $spacing-m 0;
    li {
      margin-bottom: $spacing-xs;
    }
  }

  blockquote {
    font-size: 120%;
    font-family: $font-family;
    font-weight: lighter;
    color: lighten($text-color, 40%);
    border-left: 4px solid $main-color;
    padding-left: $spacing-s;
    margin-top: $spacing-m;
    margin-bottom: $spacing-m;
    margin-left: 0;
    margin-right: 0;
    line-height: 150%;

    p {
      padding-left: 0!important;
      padding-right: 0!important;
    }
  } // end blockquote

  img {
    width: 100%;
    height: 100%;
  }


} // end #single-wrap

.book-jacket {
float: left;
margin-top: $spacing-m;
margin-right: $spacing-s;
margin-bottom: $spacing-s;
margin-left: 0;
width: 30%;
max-width: 180px;
 }

.book-jacket img {
width: 100%;
}

.book-downs {
list-style-type: none;
margin: 0;
padding: 0;

	.down {
	display: inline-block;
	margin-right: $spacing-s;
	padding: $spacing-xs $spacing-s;
	text-align: center;
	width: 25%;
	border-radius: 3px;
	-moz-border-radius: 3px;
	color: $bg-color;
	font-family: $font-family;
    @media (max-width: 642px) {
    font-size: $font-size-s;
	margin-right: $spacing-xs;
//	padding: $spacing-xs $spacing-xs;
    }

		a {
		color: $bg-color;
		text-decoration: none;

		&:hover {
		color: white;
		text-decoration: none;
		}
		}
	}

  .linky {
  color: $main-color;
  font-family: $variant-family;
  }

	.epub {
	background-color: yellowgreen;
	}

	.mobi {
	background-color: darkorange;
	}

	.text {
	background-color: darkcyan;
	}
}

.editblock {
float: left;
margin-right: $spacing-s;
margin-bottom: $spacing-s;
width: 40%;
font-size: 80%;
	p {
	float: right;
	width: 65%;
	padding: 0;
	margin: 0;
	line-height: 150%;
	      @media (max-width: 642px) {
	      float: none;
	      width: 100%;
	      text-align: center;
	      }
	}

	.editdetails {
	color: slategrey;
	}
}

.edit-jack-wrap {
  float: left;
  margin-right: $spacing-s;
  margin-bottom: $spacing-s;
  height: 150px;
  overflow: hidden;
}

.examplepic {
display: inline-block;
vertical-align: middle;
max-width: 100px;
      @media (max-width: 642px) {
      }

}

.endorsement {
font-family: $font-family;
font-size: 105%;
text-align: right;
font-style: italic;
margin-top: $spacing-m;
margin-bottom: $spacing-m;
}

.endorse-cite {
  font-size: 95%;
  font-family: $variant-family;
  color: #666;
}

.endorse-foot {
font-family: $font-family;
font-size: $font-size-s;
color: #666;
margin-top: $spacing-xl;
margin-bottom: $spacing-xl;
}

.clearfix {
clear: both;
height: 1px;
}

// Pagination

#prev,
#next {
  text-decoration: none;
  background: white;
  border: 1px solid $border-color;
  padding: $spacing-xs;
  line-height: 1;
  border-radius: 100px;
  bottom: $spacing-m;
  color: #333;
  @media (max-width: 942px) {
    position: static;
    padding: .8em $spacing-s .7em;
  }
}

#prev {
    float: left;
}

#next {
    float: right;
}

#prev:active,
#next:active {
  border-color: $main-color;
}

.nav-title {
  display: none;
  @media (max-width: 942px) {
    display: inline;
  }
}

.nav-title-prev {
  margin-left: $spacing-xs;
  margin-right: $spacing-xs;
}

.nav-title-next {
  margin-right: $spacing-xs;
  margin-left: $spacing-xs;
}

#prev:hover .nav-title,
#next:hover .nav-title {
  display: inline;
}


ul.past, ul.this {
 font-family: $variant-family;
    li {
    list-style-type: none;
    margin-bottom: 1.5em;
        time {
        margin-right: 0.5em;
        }
    }
}

.archive-tags {
font-size: 0.7em;
color: lighten($text-color, 30%);
margin: 0 0 0 7em;
font-family: $font-family;
}

#about-pic {
margin: 0 0 1em 1em;
float: right;
width: 33%;
}

// for tag cloud and archives
.tag-cloud {
  list-style: none;
  padding: 0;
  font-family: $variant-family;
  font-size: 0.8em;
  li {
    display: inline-block;
    margin: 0 1em 1em 0;
  }
}
