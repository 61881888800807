// Colors

$main-color:        crimson;  // change this color for further customization
                                      // I like http://colours.neilorangepeel.com/! Making colors semantic too ;)

$bg-color:          ghostwhite; // background-color

$text-color:        #191919;
$link-color:        $main-color;
$border-color:      #e0e1e6;
$smoke-color:       #fafafa;
$deep-color:		#000;
$border-radius:   3px;


// Typography

$font-family:       Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$variant-family:    Lora, Georgia, Times, serif;
$font-size-l:       1em; // font-size on desktop type screens
$font-size-xl:      $font-size-l * 1.2; // bigger text
$font-size-m:       $font-size-l * 0.9; // font-size under 942px wide screens
$font-size-s:       $font-size-l * 0.7; // font-size under 642px wide screens


// Spacings

$spacing-xs:        .5em;
$spacing-s:         $spacing-xs * 2;
$spacing-sm:        $spacing-s + $spacing-xs;
$spacing-m:         $spacing-sm + $spacing-xs;
$spacing-ml:        $spacing-m + $spacing-xs;
$spacing-l:         $spacing-ml + $spacing-s;
$spacing-xl:        $spacing-l + $spacing-s;
