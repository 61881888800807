// Made with the awesome http://icomoon.io/ ! 

@font-face {
  font-family: 'icomoon';
  src:url('../assets/fonts/icomoon.eot');
  src:url('../assets/fonts/icomoon.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/icomoon.woff') format('woff'),
    url('../assets/fonts/icomoon.ttf') format('truetype'),
    url('../assets/fonts/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon-twitter, .icon-github, .icon-dribbble, .icon-instagram, .auth-twitter, .auth-facebook, .auth-instagram, .auth-email, .auth-linkedin, .auth-steam, .nav-home, .nav-about, .nav-archive, .nav-search, .meta-date, .meta-tags, .linkpost, .icon-feed, .downicon, .nav-editing, .nav-blog {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  background: $bg-color;
}

.downicon {
background-color: transparent;
}

.auth-twitter, .auth-facebook, .auth-instagram, .auth-linkedin, .auth-email, .auth-steam, .nav-home, .nav-about, .nav-archive, .nav-search, .nav-editing, .nav-blog {
margin-right: 1em;
}

.meta-date, .meta-tags, .downicon {
margin-right: 0.5em;
}

.linkpost, .icon-feed {
margin-left: 0.5em;
font-size: 0.8em;
}

.icon-twitter:before {
  content: "\e901";
}

.auth-twitter:before {
  content: "\e901";
}

.auth-instagram:before {
  content: "\ea90";
}

.auth-facebook:before {
  content: "\ea8c";
}

.auth-linkedin:before {
  content: "\eaca";
}

.auth-email:before {
  content: "\e945";
}

.auth-steam:before {
  content: "\eaad";
}

.auth-list:before {
  content: "\e904";
}

.nav-home:before {
  content: "\e900";
}

.nav-about:before {
  content: "\ea0c";
}

.nav-archive:before {
  content: "\e94d";
}

.nav-blog:before {
  content: "\e905";
}

.nav-editing:before {
  content: "\e91f";
}

.nav-search:before {
  content: "\e986";
}

.meta-date:before {
  content: "\e953";
}

.meta-tags:before {
  content: "\e935";
}

.linkpost:before {
  content: "\e9cb";
}

.icon-feed:before {
  content: "\ea94";
}

.downicon:before {
  content: "\e9c2";
}